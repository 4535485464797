.App {
  font-family: sans-serif;
  /* width: 1200px; */
  /* text-align: center; */
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}
.error {
 border:1px solid red;
}

/* #root {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 89, 255, 0.459);
} */

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

/* Loader.jsx */
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  /* width: 100px;
  height: 100px; */
}

.loading-indicator:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: 'Loading';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color:white;
  text-align:center;
  font-weight:bold;
  font-size:1.5rem;        
}
.custom_err {
  border-color: red
}